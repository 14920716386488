import { useProductStore } from '~/stores/product'
import type { Product, GeneralResponse, CategoryProduct } from '@/types'

export default defineNuxtPlugin({
  name: '02.auth-prefetch',
  enforce: 'post', // working on client
  async setup() {
    const store = useProductStore()
    const { $isLogin, $useFetchAuthProfile } = useAuth()
    if ($isLogin) {
      await $useFetchAuthProfile()
      await store.setAvailableCart()
    }
  }
})
