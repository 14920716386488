import validate from "/var/www/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/var/www/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/var/www/middleware/admin.ts"),
  agent: () => import("/var/www/middleware/agent.ts"),
  user: () => import("/var/www/middleware/user.ts"),
  "user-or-agent": () => import("/var/www/middleware/userOrAgent.ts")
}