import { defineStore } from 'pinia'
import type { CategoryProduct, Product, GeneralResponse, DetailOrders } from '@/types'

export const useProductStore = defineStore('product', {
  /**
   * Returns an object representing the initial state of the application.
   *
   * @return {() => {categories: CategoryProduct[], products: Product[], comboProduct: Product[], otherProduct: Product[], availableCart: boolean}} A function that returns the initial state object.
   */
  state: () => ({
    categories: [] as CategoryProduct[],
    products: [] as Product[],
    comboProduct: [] as Product[],
    otherProduct: [] as Product[],
    availableCart: false as boolean
  }),
  actions: {
    /**
     * Sets the categories for the product.
     *
     * @param {CategoryProduct[] | undefined} categories - The categories to be set for the product.
     */
    setCategories(categories: CategoryProduct[] | undefined) {
      if (categories) {
        this.categories = categories
      }
    },
    /**
     * Sets the products array.
     *
     * @param {Product[] | undefined} products - The array of products to set.
     */
    setProduct(products: Product[] | undefined) {
      if (products) {
        this.products = products
      }
    },
    /**
     * Sets the combo product for the current instance.
     *
     * @param {Product[] | undefined} comboProduct - The combo product to set.
     */
    setComboProduct(comboProduct: Product[] | undefined) {
      if (comboProduct) {
        this.comboProduct = comboProduct
      }
    },
    /**
     * Sets the value of the 'otherProduct' property.
     *
     * @param {Product[] | undefined} otherProduct - The new value for 'otherProduct'.
     */
    setOtherProduct(otherProduct: Product[] | undefined) {
      if (otherProduct) {
        this.otherProduct = otherProduct
      }
    },
    /**
     * Sets the available cart based on the user's authentication status.
     *
     * @return {void}
     */
    async setAvailableCart() {
      const { $user, $isAgen, $isUser } = useAuth()

      if (!$user.value || !$isAgen.value) {
        return
      }

      const urlCart = computed(() => {
        if ($isUser.value) {
          return `/users/${$user.value?.uuid}/retrive-carts`
          // biome-ignore lint/style/noUselessElse: <explanation>
        } else if ($isAgen.value) {
          return `/agents/${$user.value?.uuid}/retrive-carts`
        }
        return '/'
      })

      const { data } = await useCustomFetch<GeneralResponse<DetailOrders>>(urlCart.value, {
        method: 'POST'
      })

      // TODO: Replace the if statement with a conditional assignment
      // @ts-ignore
      this.availableCart = data.value?.data?.details?.length > 0
    },

    async setProductCategory() {
      const { data } = await useCustomFetch<GeneralResponse<CategoryProduct[]>>('categories', {
        method: 'GET'
      })
      if (data.value?.data) {
        this.categories = data.value?.data
      }
    },
    async loadProduct() {
      const [products, comboProduct, otherProduct] = await Promise.all([
        useCustomFetch<GeneralResponse<Product[]>>('products', {
          method: 'GET'
        }),
        useCustomFetch<GeneralResponse<Product[]>>('home-combo-products', {
          method: 'GET'
        }),

        useCustomFetch<GeneralResponse<Product[]>>('other-products', {
          method: 'GET'
        })
      ])

      if (products.data.value?.data) {
        this.products = products.data.value?.data
      }

      if (comboProduct.data.value?.data) {
        this.comboProduct = comboProduct.data.value?.data
      }

      if (otherProduct.data.value?.data) {
        this.otherProduct = otherProduct.data.value?.data
      }
    },
    /**
     * Sets the availability of the cart manually.
     *
     * @param {boolean} available - The availability status of the cart.
     */
    manualSetAvailableCart(availabel: boolean) {
      this.availableCart = availabel
    }
  }
})
